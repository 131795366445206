import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import WishlistList from '../wishlist/WishlistList';

function WishlistListView() {
           
    return (
        <Box mx={3} mt={2}>
            <Grid container py={2}>
                <WishlistList />
            </Grid>
            
            
        </Box>
        
    )
}

export default WishlistListView;