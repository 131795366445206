import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { db, UserContext} from '../../App';
import { collection, doc, getDoc, onSnapshot, query, where, deleteDoc } from "firebase/firestore";
import { Grid, Paper, Box, Typography, Divider, IconButton } from '@mui/material';
import { DataGrid} from '@mui/x-data-grid';
import CreateWishListDialog from './CreateWishListDialog';
import _ from 'lodash';
import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function WishlistList(props) {
    
    const { userId } = useParams();
    const user = useContext(UserContext);

    const [ wishListUserId ] = useState(userId ? userId : user.uid);
    const [ wishListUserName, setWishlistUserName ] = useState();
    const [ wishLists, setWishLists ] = useState();
 
    const navigate = useNavigate();

    useEffect(() => {
        let wishlistSnapshot;
        const getWishList = async () => {
            const wishlistDB = collection(db, "wishlists");
            let queryUser = await getDoc(doc(db, "users", wishListUserId));
            setWishlistUserName(queryUser.data().name);
            const wlQuery = query(wishlistDB, where("user", "==", queryUser.ref))
            wishlistSnapshot = onSnapshot(wlQuery,
                (wlSnapshot) => {
                    let wls = [];
                    wlSnapshot.forEach(
                        (doc) => {
                            wls.push({...doc.data(), id: doc.id});
                        }
                    );
                    setWishLists(wls);
                }
            );
            
        }
        getWishList();
        return () => wishlistSnapshot();
    }, [wishListUserId]);

    const handleDeleteWishlist = async (e, wishlistId) => {
        e.ignore = true;
        await deleteDoc(doc(db, 'wishlists', wishlistId));
    }

    const handleRowClick = (params, e, details) => {
        if (!e.ignore)
            navigate(`/wishlists/${wishListUserId}/details/${params.id}`);
    }

    const renderDateCell = (cellValues) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const eventDate = cellValues.row.eventDate.toDate().toLocaleDateString("en-US", options);
        return <span>{eventDate}</span>

    }

    const renderDeleteCell = (cellValues) => {
        return (
            <IconButton onClick={(e) => handleDeleteWishlist(e, cellValues.row.id)}>
                <Delete />
            </IconButton>
        )
    }

    let cols = [
        {field: 'id', hide: true},
        {field: 'name', headerName: 'Wishlist Name', flex:1},
        {field: 'eventType', headerName: 'Event', flex:1},
        {field: 'eventDate', headerName: 'Date', flex:1, renderCell: renderDateCell}
    ]

    if (user.uid === wishListUserId)
        cols = _.concat(cols, {field: 'delete', headerName: "", flex:.5, renderCell: renderDeleteCell, align: 'right'})

    return (
        <Grid item xs={12}>
            <Paper elevation={5} sx={{backgroundColor:"#E5DAA7"}}>
                <Box p={2}>
                    <Grid container alignItems="center">
                        <Grid item xs={10}>
                            <Typography 
                                variant="h6" 
                                
                                sx={ 
                                    {
                                        fontFamily: 'Tangerine',
                                        fontSize: 40,
                                        textShadow: '4px 4px 4px #9F434E',
                                        color: "#881522"
                                    }
                                }
                            >
                                {user.uid === wishListUserId ? "My" : `${wishListUserName}'s`} Wishlists
                            </Typography>
                        </Grid>
                        <Grid item xs={2}  sx={{textAlign:"right"}}>
                            <CreateWishListDialog wishlistUserId={wishListUserId} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider xs={{backgroundColor: "#E5DAA7"}} />
                        </Grid>
                        <Grid item xs={12}>
                            <DataGrid rows={wishLists ? wishLists : []} columns={cols} autoHeight={true} xs={{borderColor:"#E5DAA7"}} onRowClick={handleRowClick}/>
                        </Grid>
                    </Grid>

                </Box>
            </Paper>
        </Grid>
    )
}

export default WishlistList
