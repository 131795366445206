import './App.css';
import { useState, createContext, useCallback, useEffect } from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Layout from './components/layout/Layout'
import { BrowserRouter as Router } from 'react-router-dom';
import { getFirestore} from "firebase/firestore";
import {  
  doc, 
  getDoc, 
  setDoc,
  updateDoc
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCZwIxAU7OIkFJk3Yj3J_57-yh2wbPRXP0",
  authDomain: "wishlist-4a395.firebaseapp.com",
  projectId: "wishlist-4a395",
  storageBucket: "wishlist-4a395.appspot.com",
  messagingSenderId: "767315005648",
  appId: "1:767315005648:web:863a4d8156d79d26a40ed5"
};

firebase.initializeApp(firebaseConfig);

export const db = getFirestore();

export const UserContext = createContext();

function App() {
  const [ user, setUser ] = useState();
  
  const logout = useCallback(
    () => {
      firebase.auth().signOut();
      setUser(undefined);
    },
    [setUser],
  )
  
  useEffect(() => { 
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      loginUser => {
        if (loginUser) {
          setUser(
            {
              name: loginUser.displayName,
              email: loginUser.email,
              uid: loginUser.uid,
              photo: loginUser.photoURL,
              logout: logout
            }
          )

          const checkUser = async () => {
            const userRef = doc(db, "users", loginUser.uid);
            const userDoc = await getDoc(userRef);
            if(!userDoc.exists()) {
              await setDoc(userRef, 
                {
                  name:loginUser.displayName,
                  email: loginUser.email,
                  photo: loginUser.photoURL
                }
              )
            } else {
              if(!userDoc.data().photo || userDoc.data().photo !== loginUser.photoURL) {
                await updateDoc(userRef,
                  {
                    photo: loginUser.photoURL
                  }  
                )
              }

            }
          }
          checkUser();
        }
        else {
          setUser(undefined);
        }
      }
    )

    return () => {
      unregisterAuthObserver();
    }
  }, [setUser, logout])
  
  const uiConfig = (
    {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => false,
      }
    }
  )

  return (
    <div>
      { 
        !user ? 
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} /> 
        : 
          <UserContext.Provider value={user}>
            <Router>
              <Layout />
            </Router>
          </UserContext.Provider>
          
      }
      
    </div>
  );
}

export default App;
