import React, { useContext, useState } from 'react'
import { AppBar, Avatar, Box, createTheme, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { ThemeProvider } from '@emotion/react';
import { UserContext } from '../../App';
import ApplicationRoutes from '../../ApplicationRoutes';
import { useNavigate } from 'react-router';

const theme = createTheme( 
    {
        palette: {
            type: 'light',
            primary: {
                main: '#148F6F',
            },
            secondary: {
                main: '#881522',
            },
            divider: '#881522',
            text: {
                secondary: '#881522',
            }
        }
    }
);

function Layout() {
    const user = useContext(UserContext);
    const navigate = useNavigate();

    const [ anchorEl, setAnchorEl ] = useState(false);

    const openMenu = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closeMenu = () => {
        setAnchorEl(null);
    }

    const signOut = (e) => {
        user.logout();
        closeMenu();
    }

    return (
        <ThemeProvider theme={theme} >
            
            <AppBar 
                position="static"
            >
                <Toolbar>
                    <Box sx={{flexGrow: 1 }}>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={ 
                                {
                                    fontFamily: 'Tangerine',
                                    fontSize: 64,
                                    textShadow: '4px 4px 4px #9F434E',
                                    color: '#881522',
                                    cursor: "pointer"
                                }
                            }
                            onClick={(e) => navigate('/')}
                            
                        >
                            ObbDenEgaCotWatEro Wishlist&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 0}}>
                        <IconButton sx={{ p:0 }} onClick={openMenu}>
                            <Avatar alt={user.name} src={user.photo} />
                        </IconButton>
                        <Menu 
                            anchorEl={anchorEl}
                            anchorOrigin={
                                {
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }
                            }
                            keepMounted
                            onClose={closeMenu}
                            open={Boolean(anchorEl)}
                        >
                            <MenuItem onClick={signOut}>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <ApplicationRoutes />

        </ThemeProvider>
    )
}

export default Layout
