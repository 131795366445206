import React, { useState, useContext, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete, Grid } from '@mui/material';
import { holidayList } from '../../utils/holidays'
import DateAdapter from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { collection, doc, getDoc, addDoc, Timestamp } from "firebase/firestore";
import { DatePicker } from '@mui/lab';
import { db, UserContext } from '../../App';
import _ from 'lodash';
import { useNavigate } from 'react-router';

function CreateWishListDialog(props) {
    
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ formValues, setFormValues ] = useState({name: "", eventType: "", eventDate: ""})
    const [ formErrors, setFormErrors ] = useState(
        {
            name: {
                error: false,
                message: ""
            },
            eventType: {
                error: false,
                message: ""
            },
            eventDate: {
                error: false,
                message: ""
            }
        }
    )

    const user = useContext(UserContext);

    const navigate = useNavigate();

    const handleOpenDialog = (e) => {
        setDialogOpen(true);
    }

    const handleCloseDialog = (e) => {
        setDialogOpen(false);
        setFormValues({name: "", eventType: "", eventDate: ""})
    }

    useEffect(() => {
        setFormErrors(
            currentErrors => {
                let newErrors = _.cloneDeep(currentErrors);
                
                if(!formValues.name || formValues.name === "") {
                    newErrors = {
                        ...newErrors,
                        name: {
                            error: true,
                            message: "You must enter a wishlist name."
                        }
                    }
                } else if(formErrors.name.error) {
                    newErrors = {
                        ...newErrors,
                        name: {
                            error: false,
                            message: ""
                        }
                    }
                }

                const holidayArray = holidayList.map(
                    (holiday) => {
                        return holiday.holiday;
                    }
                )

                if(!formValues.eventType || formValues.eventType === "" || _.indexOf(holidayArray, formValues.eventType) === -1) {
                    newErrors = {
                        ...newErrors,
                        eventType: {
                            error: true,
                            message: "You must select a valid holiday."
                        }
                    }
                } else if(formErrors.eventType.error) {
                    newErrors = {
                        ...newErrors,
                        eventType: {
                            error: false,
                            message: ""
                        }
                    }
                }

                if (!formValues.eventDate || formValues.eventDate.invalid || formValues.eventDate === "") {
                    newErrors = {
                        ...newErrors,
                        eventDate: {
                            error: true,
                            message: formValues.eventDate && formValues.eventDate.invalid ? formValues.eventDate.invalid.explanation : "Must enter a valid date."
                        }
                    }
                } else if(formErrors.eventDate.error) {
                    newErrors = {
                        ...newErrors,
                        eventDate: {
                            error: false,
                            message: ""
                        }
                    }
                }

                if (!_.isEqual(newErrors, currentErrors))
                    return newErrors
                else
                    return currentErrors
            }
        )
        
    }, [formValues, setFormErrors, formErrors])

    const updateFormValues = (e, newValue) => {
        const value = newValue ? newValue.holiday : e.target.type === "checkbox" ? e.target.checked : e.target.value;
        const attrName = newValue ? "eventType" : e.target.name;
        setFormValues(
            currentValues => {
                return {
                    ...currentValues,
                    [attrName]: value
                }
            }
        )
    }

    const updateDateValues = (newValue) => {
        
        setFormValues(
            currentValues => {
                return {
                    ...currentValues,
                    eventDate: newValue
                }
            }
        );
            
    }

    const createWishList = async (e) => {

        let queryUser = await getDoc(doc(db, "users", user.uid));
        const wishlistData = {...formValues, user: queryUser.ref, eventDate: Timestamp.fromDate(formValues.eventDate.toJSDate())};
        const newDoc = await addDoc(collection(db, "wishlists"), wishlistData);
        handleCloseDialog();
        navigate(`/wishlists/${props.wishlistUserId}/details/${newDoc.id}`);
    }

    if(props.wishlistUserId === user.uid)
        return (
            
            <React.Fragment>
                <Button color="secondary" variant="contained" onClick={handleOpenDialog}>Create Wishlist</Button>
                <Dialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        Create a New Wishlist
                    </DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField 
                                    label="Wishlist Name"
                                    value={formValues.name}
                                    onChange={updateFormValues}
                                    margin="normal"
                                    fullWidth
                                    required
                                    name="name"
                                    error={formErrors.name.error}
                                    helperText={formErrors.name.message}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <Autocomplete
                                    id="eventType"
                                    options={holidayList}
                                    groupBy={(option) => option.month}
                                    getOptionLabel={(option) => option.holiday}
                                    renderInput={(params) => <TextField {...params} label="Event Type" error={formErrors.eventType.error} helperText={formErrors.eventType.message}/>}
                                    onChange={updateFormValues}
                                    name="eventType"
                                    required
                                    
                                />
                            </Grid>
                            <Grid item xs={5} pl={2}>
                                <LocalizationProvider dateAdapter={DateAdapter}>
                                    <DatePicker
                                        label="Event Date"
                                        value={formValues.eventDate}
                                        renderInput={(params) => <TextField {...params} error={formErrors.eventDate.error} helperText={formErrors.eventDate.message}/>}
                                        onChange={updateDateValues}
                                        fullWidth
                                        
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" onClick={handleCloseDialog}>Cancel</Button>
                        <Button 
                            variant="text" 
                            disabled={formErrors.name.error || formErrors.eventType.error || formErrors.eventDate.error}
                            onClick={createWishList}
                        >
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    else
        return <React.Fragment />
}

export default CreateWishListDialog
