import { Button } from '@mui/material'
import { GridAddIcon, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid'
import React from 'react'

function DataGridToolBar(props) {
    return (
        <GridToolbarContainer style={{justifyContent:"flex-end"}}>
            <GridToolbarFilterButton />
            <GridToolbarExport />
            <DataGridAddRow addRow={props.addRow} disabled={props.disabled} />
        </GridToolbarContainer>
    )
}

function DataGridAddRow(props) {

    const handleAddRow = (e) => {
        props.addRow();
    }

    return(
        <Button size="small" startIcon={<GridAddIcon />} onClick={handleAddRow} disabled={props.disabled}>
            Add
        </Button>
    );
}

export default DataGridToolBar
