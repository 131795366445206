import { collection, onSnapshot, query, where } from '@firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import { Grid, Paper, Box, Typography, Divider, Card, CardContent, CardActions, Button, Avatar } from '@mui/material';
import { db, UserContext } from '../../App'
import { useNavigate } from 'react-router';

function PeopleList() {
    
    const [ peopleList, setPeopleList ] = useState([]);

    const user = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        let peopleSnap;
        const getPeopleData = async () => {
            
            const peopleQuery = query(collection(db, "users"), where("__name__", "!=", user.uid));
            peopleSnap = onSnapshot(peopleQuery,
                (queryResults) => {
                    let people = [];
                    queryResults.forEach(
                        (doc) => {
                            people.push({...doc.data(), id: doc.id});
                        }
                    )
                    setPeopleList(people);
                }

            )
        }

        getPeopleData();

        return () => {
            peopleSnap();
        }
    }, [user, setPeopleList]);
    
    return (
        <Grid item xs={12}>
            <Paper elevation={5} sx={{backgroundColor:"#E5DAA7"}}>
                <Box p={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography 
                                variant="h6" 
                                
                                sx={ 
                                    {
                                        fontFamily: 'Tangerine',
                                        fontSize: 40,
                                        textShadow: '4px 4px 4px #9F434E',
                                        color: "#881522"
                                    }
                                }
                            >
                                My Family Members
                            </Typography>
                        </Grid>
                        <Grid item xs={12} pb={1}>
                            <Divider xs={{backgroundColor: "#E5DAA7"}} />
                        </Grid>
                        
                        {
                            peopleList.map(
                                (person) => {
                                    return (
                                        <Grid item xs={3} p={1} key={person.name}>
                                            <Card>
                                                <CardContent>
                                                    <Avatar alt={person.name} src={person.photo} />
                                                        <Typography pt={2}>
                                                            {person.name}
                                                        </Typography>                                                 
                                                </CardContent>
                                                <CardActions>
                                                    <Button 
                                                        size="small"
                                                        onClick={
                                                            (e) => {
                                                                navigate(`/wishlists/${person.id}`)
                                                            }
                                                        }
                                                    >
                                                        View Wishlists
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )
                                }
                            )
                        }
                    </Grid>

                </Box>
            </Paper>
        </Grid>
    )
}

export default PeopleList
