import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Grid, Paper, Box, Typography, Divider, IconButton, Button } from '@mui/material';
import { 
    collection, 
    doc, 
    onSnapshot, 
    query, 
    where, 
    getDoc, 
    addDoc, 
    updateDoc,
    deleteDoc
 } from 'firebase/firestore';
import { db, UserContext } from '../../App';
import { DataGrid } from '@mui/x-data-grid';
import DataGridToolBar from '../datagrid/DataGridToolBar';
import { Delete } from '@mui/icons-material';
import _ from 'lodash';

function WishlistDetails() {
    
    const [ wishlist, setWishlist ] = useState({name: "", eventType: "", eventDate: ""});
    const [ wishlistItems, setWishlistItems ] = useState([]);
    const [ wishlistUser, setWishlistUser ] = useState("");
    const [ wishlistRef, setWishlistRef ] = useState();

    const { wishlistId } = useParams();

    const user = useContext(UserContext);

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const formatter = new Intl.NumberFormat('en-US', 
        {
            style: 'currency',
            currency: 'USD'
        }
    )

    useEffect(() => {
        let wishlistSnap;
        let wishlistItemsSnap;
        const getWishlistDetails = async () => {
            wishlistSnap = onSnapshot(doc(db, "wishlists", wishlistId),
                async (doc) => {
                    setWishlist(doc.data());
                    setWishlistRef(doc.ref);
                    const wliQuery = query(collection(db, "wishlistItems"), where("wishlist", "==", doc.ref))
                    wishlistItemsSnap = onSnapshot(wliQuery, 
                        (wliDocs) => {
                            let wlis = [];
                            wliDocs.forEach(
                                (wliDoc) => {
                                    wlis.push({...wliDoc.data(), id: wliDoc.id})
                                }
                            );
                            setWishlistItems(wlis);
                        }
                    )
                    const userData = await getDoc(doc.data().user)
                    setWishlistUser({...userData.data(), id: userData.id});
                }
            )
        }

        getWishlistDetails();
        
        return () => { wishlistSnap(); wishlistItemsSnap();}
    }, [setWishlist, wishlistId])

    const getCurrency = (params) => {
        return formatter.format(params.value);
    }

    const handleDeleteWishlistItem = async (e, docId) => {
        e.ignore = true;
        await deleteDoc(doc(db, 'wishlistItems', docId));
    }

    const updateWishlistItemStatus = async (status) => {
        const itemRef = doc(db, "wishlistItems", status.id);
        await updateDoc(itemRef,
            {
                claimed: status.claimed,
                claimedBy: status.claimedBy
            }
        )
    }

    const renderLinkCell = (params) => {
        if(!params.row.link)
            return ""
        return <a href={params.row.link} target="_blank" rel="noreferrer">Link</a>
    }

    const renderDeleteCell = (cellValues) => {
        return (
            <IconButton onClick={(e) => handleDeleteWishlistItem(e, cellValues.row.id)}>
                <Delete />
            </IconButton>
        )
    }

    const renderClaimCell = (cellValues) => {
        const claimed = cellValues.row.claimed;
        const claimedBy = cellValues.row.claimedBy;
        if(!claimed) {
            return(
                <Button 
                    size="small" 
                    color="primary"
                    variant="contained"
                    onClick={
                        (e) => {
                            updateWishlistItemStatus({id:cellValues.row.id, claimed:true, claimedBy:user.uid})
                        }
                    }
                >
                    Dibs!
                </Button>
            )
        } else {
            if(user.uid === claimedBy) {
                return(
                    <Button 
                        size="small" 
                        color="primary"
                        variant="contained"
                        onClick={
                            (e) => {
                                updateWishlistItemStatus({id:cellValues.row.id, claimed:false, claimedBy:""})
                            }
                        }
                    >
                        Undibs
                    </Button>
                )
            } else{
                return(
                    "Claimed"
                )
            }
        }
        
    }

    let cols = [
        {field: "id", hide: true},
        {field: "name", headerName: "Item Name", flex: 1, editable: user.uid === wishlistUser.id},
        {field: "description", headerName: "Item Description", flex: 2, editable: user.uid === wishlistUser.id},
        {field: "price", headerName: "Item Price", flex: .5, valueFormatter: getCurrency, editable: user.uid === wishlistUser.id},
        {field: "suggestedStores", headerName: "Suggested Stores", flex: 1, editable: user.uid === wishlistUser.id},
        {field: "link", headerName: "Store Link", flex: .5, renderCell: renderLinkCell, editable: user.uid === wishlistUser.id}
    ]

    if(user.uid === wishlistUser.id) {
        cols = _.concat(cols, {field: "delete", headerName: "", flex: .2, renderCell: renderDeleteCell, align:"center"})
    } else if(wishlistUser) {
        cols = _.concat(cols, {field: "available", headerName: "Available", flex: .4, renderCell: renderClaimCell, align:"center"});
    }

    const addRow = async () => {
        const defaultItem = {
            name: "",
            description: "",
            price: 0,
            suggestedStores: "",
            link: "",
            claimed: false,
            claimedBy: "",
            wishlist: wishlistRef
        }

        let newItem = await addDoc(collection(db, "wishlistItems"), defaultItem);
        console.log(newItem);
    }

    const updateWishlistItem = async (params, e, details) => {
        const itemRef = doc(db, "wishlistItems", params.id);
        await updateDoc(itemRef,
            {
                [params.field]: params.value
            }
        )
        
    }

    return (
        <Box mx={3} mt={2}>
            <Grid container py={2}>
                <Grid item xs={12}>
                    <Paper elevation={5} sx={{backgroundColor:"#E5DAA7"}}>
                        <Box p={2}>
                            <Grid container alignItems="center">
                                <Grid item xs={4}>
                                    <Typography 
                                        variant="h6" 
                                        
                                        sx={ 
                                            {
                                                fontFamily: 'Tangerine',
                                                fontSize: 40,
                                                textShadow: '4px 4px 4px #9F434E',
                                                color: "#881522"
                                            }
                                        }
                                    >
                                        {wishlist.name} for {wishlistUser.name}
                                    </Typography>
                                </Grid>
                                
                                <Grid item xs={4} textAlign="center">
                                    <Typography 
                                        variant="h6" 
                                        
                                        sx={ 
                                            {
                                                fontFamily: 'Tangerine',
                                                fontSize: 40,
                                                textShadow: '4px 4px 4px #9F434E',
                                                color: "#881522"
                                            }
                                        }
                                    >
                                        Event: {wishlist.eventType}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="right">
                                    <Typography 
                                        variant="h6" 
                                        
                                        sx={ 
                                            {
                                                fontFamily: 'Tangerine',
                                                fontSize: 40,
                                                textShadow: '4px 4px 4px #9F434E',
                                                color: "#881522"
                                            }
                                        }
                                    >
                                        {wishlist.eventDate ? wishlist.eventDate.toDate().toLocaleDateString("en-US", options) : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider xs={{backgroundColor: "#E5DAA7"}} />
                                </Grid>
                                <Grid item xs={12}>
                                    <DataGrid 
                                        rows={wishlistItems} 
                                        columns={cols} 
                                        autoHeight={true} 
                                        components={{Toolbar: DataGridToolBar}}
                                        componentsProps={{toolbar: {addRow: addRow, disabled:user.uid !== wishlistUser.id}}}
                                        onCellEditCommit={updateWishlistItem}
                                    />
                                </Grid>
                            </Grid>

                        </Box>
                    </Paper>
                </Grid>        
            </Grid>            
        </Box>
    )
}

export default WishlistDetails
