
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/layout/Home';
import WishlistDetails from './components/wishlist/WishlistDetails';
import WishlistListView from './components/wishlist/WishlistListView';


function ApplicationRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/wishlists/:userId" element={<WishlistListView />} />
            <Route path="/wishlists/:userId/details/:wishlistId" element={<WishlistDetails />} />
        </Routes>
    )
}

export default ApplicationRoutes;