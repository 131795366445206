import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import WishlistList from '../wishlist/WishlistList';
import PeopleList from '../people/PeopleList';

function Home() {
           
    return (
        <Box mx={3} mt={2}>
            <Grid container py={2}>
                <WishlistList />
                <Grid item xs={12} p={3}>
                    <Divider />
                </Grid>
                <PeopleList />
            </Grid>
            
            
        </Box>
        
    )
}

export default Home;