export const holidayList = [
  {
    "month": "January",
    "holiday": "New Year"
  },
  {
    "month": "January",
    "holiday": "Epiphany"
  },
  {
    "month": "January",
    "holiday": "Blue Monday"
  },
  {
    "month": "January",
    "holiday": "Martin Luther King Jr. Day"
  },
  {
    "month": "January",
    "holiday": "Australia Day"
  },
  {
    "month": "February",
    "holiday": "Black History Month"
  },
  {
    "month": "February",
    "holiday": "Groundhog Day"
  },
  {
    "month": "February",
    "holiday": "National Pizza Day"
  },
  {
    "month": "February",
    "holiday": "Chinese New Year"
  },
  {
    "month": "February",
    "holiday": "Superbowl"
  },
  {
    "month": "February",
    "holiday": "Valentine's Day"
  },
  {
    "month": "February",
    "holiday": "Carnival/Shrove Tuesday"
  },
  {
    "month": "February",
    "holiday": "Shrove Tuesday/Mardi Gras"
  },
  {
    "month": "February",
    "holiday": "Ash Wednesday"
  },
  {
    "month": "February",
    "holiday": "National Margarita Day"
  },
  {
    "month": "February",
    "holiday": "Purim"
  },
  {
    "month": "March",
    "holiday": "Women's History Month"
  },
  {
    "month": "March",
    "holiday": "International Women's Day"
  },
  {
    "month": "March",
    "holiday": "Pi Day"
  },
  {
    "month": "March",
    "holiday": "Ides of March"
  },
  {
    "month": "March",
    "holiday": "St. Patrick's Day"
  },
  {
    "month": "March",
    "holiday": "March equinox"
  },
  {
    "month": "March",
    "holiday": "Feast of the Annunciation"
  },
  {
    "month": "March",
    "holiday": "Palm Sunday"
  },
  {
    "month": "April",
    "holiday": "April Fool's Day"
  },
  {
    "month": "April",
    "holiday": "Maundy Thursday"
  },
  {
    "month": "April",
    "holiday": "Good Friday"
  },
  {
    "month": "April",
    "holiday": "Easter"
  },
  {
    "month": "April",
    "holiday": "National Beer Day"
  },
  {
    "month": "April",
    "holiday": "Siblings Day"
  },
  {
    "month": "April",
    "holiday": "Ramadan"
  },
  {
    "month": "April",
    "holiday": "Tax Day"
  },
  {
    "month": "April",
    "holiday": "Record Store Day"
  },
  {
    "month": "April",
    "holiday": "Earth Day"
  },
  {
    "month": "April",
    "holiday": "Arbor Day"
  },
  {
    "month": "May",
    "holiday": "Batman Day"
  },
  {
    "month": "May",
    "holiday": "Free Comic Book Day"
  },
  {
    "month": "May",
    "holiday": "Labor Day / May Day"
  },
  {
    "month": "May",
    "holiday": "Cinco de Mayo"
  },
  {
    "month": "May",
    "holiday": "National Day of Prayer"
  },
  {
    "month": "May",
    "holiday": "Mother's Day"
  },
  {
    "month": "May",
    "holiday": "Eid-e-Fetr"
  },
  {
    "month": "May",
    "holiday": "Armed Forces Day"
  },
  {
    "month": "May",
    "holiday": "Pentecost"
  },
  {
    "month": "May",
    "holiday": "Memorial Day"
  },
  {
    "month": "June",
    "holiday": "Corpus Christi"
  },
  {
    "month": "June",
    "holiday": "National Donut Day"
  },
  {
    "month": "June",
    "holiday": "D-Day"
  },
  {
    "month": "June",
    "holiday": "Flag Day"
  },
  {
    "month": "June",
    "holiday": "Juneteenth"
  },
  {
    "month": "June",
    "holiday": "Father's Day"
  },
  {
    "month": "June",
    "holiday": "June Solstice"
  },
  {
    "month": "July",
    "holiday": "Canada Day"
  },
  {
    "month": "July",
    "holiday": "Independence Day"
  },
  {
    "month": "July",
    "holiday": "Bastille Day"
  },
  {
    "month": "July",
    "holiday": "National Ice Cream Day"
  },
  {
    "month": "July",
    "holiday": "Eid-al-Adha"
  },
  {
    "month": "August",
    "holiday": "National Girlfriends Day"
  },
  {
    "month": "August",
    "holiday": "Victory Day"
  },
  {
    "month": "August",
    "holiday": "Friday the 13th"
  },
  {
    "month": "August",
    "holiday": "The Assumption of Mary"
  },
  {
    "month": "August",
    "holiday": "Ashura"
  },
  {
    "month": "August",
    "holiday": "National Dog Day"
  },
  {
    "month": "September",
    "holiday": "Labor Day"
  },
  {
    "month": "September",
    "holiday": "Rosh Hashana"
  },
  {
    "month": "September",
    "holiday": "National Grandparents Day"
  },
  {
    "month": "September",
    "holiday": "Yom Kippur"
  },
  {
    "month": "September",
    "holiday": "Constitution Day and Citizenship Day"
  },
  {
    "month": "September",
    "holiday": "Sukkot"
  },
  {
    "month": "September",
    "holiday": "Mabon"
  },
  {
    "month": "September",
    "holiday": "September equinox"
  },
  {
    "month": "September",
    "holiday": "National Coffee Day"
  },
  {
    "month": "October",
    "holiday": "National Boyfriend Day"
  },
  {
    "month": "October",
    "holiday": "Leif Erikson Day"
  },
  {
    "month": "October",
    "holiday": "Columbus Day"
  },
  {
    "month": "October",
    "holiday": "National Coming Out Day"
  },
  {
    "month": "October",
    "holiday": "Sweetest Day"
  },
  {
    "month": "October",
    "holiday": "National Cat Day"
  },
  {
    "month": "October",
    "holiday": "Halloween"
  },
  {
    "month": "November",
    "holiday": "All Saints' Day"
  },
  {
    "month": "November",
    "holiday": "Diwali"
  },
  {
    "month": "November",
    "holiday": "Guy Fawkes Day"
  },
  {
    "month": "November",
    "holiday": "National Doughnut Day"
  },
  {
    "month": "November",
    "holiday": "Marine Corps Birthday"
  },
  {
    "month": "November",
    "holiday": "Veterans Day"
  },
  {
    "month": "November",
    "holiday": "International Men's Day"
  },
  {
    "month": "November",
    "holiday": "Thanksgiving Day"
  },
  {
    "month": "November",
    "holiday": "Black Friday"
  },
  {
    "month": "November",
    "holiday": "Small Business Saturday"
  },
  {
    "month": "November",
    "holiday": "First Sunday of Advent"
  },
  {
    "month": "November",
    "holiday": "Cyber Monday"
  },
  {
    "month": "November",
    "holiday": "Hanukkah"
  },
  {
    "month": "November",
    "holiday": "Giving Tuesday"
  },
  {
    "month": "December",
    "holiday": "St. Nicholas Day"
  },
  {
    "month": "December",
    "holiday": "December Solstice"
  },
  {
    "month": "December",
    "holiday": "Yule"
  },
  {
    "month": "December",
    "holiday": "Christmas Eve"
  },
  {
    "month": "December",
    "holiday": "Christmas"
  },
  {
    "month": "December",
    "holiday": "Boxing Day"
  },
  {
    "month": "December",
    "holiday": "Kwanzaa"
  },
  {
    "month": "December",
    "holiday": "New Year's Eve"
  },
  {
    "month": "Other",
    "holiday": "Birthday"
  },
  {
    "month": "Other",
    "holiday": "Anniversary"
  },
  {
    "month": "Other",
    "holiday": "Engagement Party"
  },
  {
    "month": "Other",
    "holiday": "Wedding"
  },
  {
    "month": "Other",
    "holiday": "Baby Shower"
  },
  {
    "month": "Other",
    "holiday": "Funeral"
  },
  {
    "month": "Other",
    "holiday": "Bachelor/Bachelorette Party"
  }
]
